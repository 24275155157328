import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import pt from "vuetify/lib/locale/pt";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#ff664f",
        secondary: "#533ef9",
        accent: "#FED8B1",
        error: "#ff5252",
        info: "#2196f3",
        success: "#4caf50",
        warning: "#ffc107",
        background: "#ffffff",
        background1: "#f8f8fa",
        background2: "#e7e9ef",
        background3: "#d2d7e5",
        backgroundBlue: "#090b2a",
        primaryText: "#2b2b2b",
        secondaryText: "#818080",
      },
    },
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
});
