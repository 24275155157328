import axios from "axios";
import store from "@/store";
import router from "@/router";

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL + "/brands",
  headers: { secret: process.env.VUE_APP_API_SECRET },
  timeout: 16000,
});

if (store && store.getters.token)
  api.defaults.headers.common["Authorization"] = store.getters.token;

api.interceptors.response.use(
  (res) => {
    if (res.data && res.data.status === "OK") return Promise.resolve(res);

    return Promise.reject(res);
  },
  (err) => {
    if (
      (err.message && err.message.includes("Network Error")) ||
      (err.request.status >= 500 && store.getters.token) ||
      [401, 403].includes(err.request.status)
    ) {
      store.dispatch("setSignOut");
      router.push({ path: "/" }).catch(() => {
        /* ignore */
      });
    }

    return Promise.reject(err);
  }
);

export async function request(data, path = "") {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  return api.post(path, formData);
}

export default api;
