import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import api from "@/services";

Vue.use(Vuex);

const vuexLocal = new VuexPersist({
  key: "localStorage",
  storage: window.localStorage, // persistent on localstorage
  modules: ["token", "user"], // persistent variable on state
});

export default new Vuex.Store({
  state: {
    token: null,
    user: null,
  },

  getters: {
    authenticated: ({ token, user }) => !!token && !!user,
    token: ({ token }) => token,
    user: ({ user }) => user,
  },

  mutations: {
    SET_TOKEN(state, payload) {
      state.token = payload;
    },

    SET_USER(state, payload) {
      state.user = payload;
    },
  },

  actions: {
    setToken({ commit }, payload) {
      commit("SET_TOKEN", payload);
      api.defaults.headers.common["Authorization"] = payload;
    },

    setUser({ commit }, payload) {
      commit("SET_USER", payload);
    },

    setSignOut({ dispatch }) {
      dispatch("setToken", null);
      dispatch("setUser", null);
    },
  },

  plugins: [vuexLocal.plugin],

  modules: {},
});
