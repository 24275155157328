<template>
  <div class="main-wrapper">
    <v-navigation-drawer
      v-if="$vuetify.breakpoint.mdAndUp"
      v-model="drawer"
      :color="$vuetify.breakpoint.lgAndUp ? 'transparent' : 'background'"
      floating
      app
    >
      <div class="d-flex flex-column fill-height">
        <v-card
          :class="$vuetify.breakpoint.lgAndUp ? 'ma-2 rounded-lg' : 'rounded-0'"
          color="neutral"
          height="100%"
          flat
        >
          <div class="d-flex fill-height">
            <div
              class="secondary left-marker"
              :class="
                $vuetify.breakpoint.lgAndUp ? 'rounded-l-lg' : 'rounded-0'
              "
            />

            <div class="d-flex flex-column fill-width">
              <div class="d-flex justify-center mt-6 mb-8">
                <v-img
                  :src="require('@/assets/logo.svg')"
                  contain
                  max-width="8rem"
                />
              </div>

              <v-list
                class="py-0"
                color="transparent"
                width="100%"
                height="100%"
                dense
                nav
              >
                <router-link
                  v-for="(item, i) in routes"
                  v-slot="{ href, navigate, isActive }"
                  :to="item.path"
                  :key="i"
                  custom
                >
                  <v-list-item
                    class="rounded-lg"
                    :href="href"
                    :class="{
                      background2: isActive,
                      disabled: item.disabled || item.isComing,
                    }"
                    @click="navigate"
                  >
                    <v-list-item-icon class="mr-3">
                      <v-img
                        :class="{
                          'disabled-icon': item.disabled || item.isComing,
                        }"
                        :src="require(`@/assets/menu/${item.icon}.svg`)"
                        max-width="1.25rem"
                        contain
                      />
                    </v-list-item-icon>

                    <v-list-item-title class="d-flex">
                      <span
                        :class="
                          item.disabled || item.isComing
                            ? 'secondaryText--text'
                            : 'primaryText--text'
                        "
                      >
                        {{ item.label }}
                      </span>
                    </v-list-item-title>
                  </v-list-item>
                </router-link>
              </v-list>

              <v-list class="pt-0" color="transparent" width="100%" dense nav>
                <router-link
                  v-slot="{ href, navigate, isActive }"
                  to="/profile"
                  custom
                >
                  <v-list-item
                    class="rounded-lg"
                    :href="href"
                    :class="{
                      background2: isActive,
                    }"
                    @click="navigate"
                  >
                    <v-list-item-icon class="mr-3">
                      <v-img
                        :src="require('@/assets/menu/settings-sliders.svg')"
                        max-width="1.25rem"
                        contain
                      />
                    </v-list-item-icon>

                    <v-list-item-title> Meus dados </v-list-item-title>
                  </v-list-item>
                </router-link>

                <v-list-item class="rounded-lg" @click="signOut()">
                  <v-list-item-icon class="mr-3">
                    <v-img
                      :src="require('@/assets/menu/exit.svg')"
                      max-width="1.25rem"
                      contain
                    />
                  </v-list-item-icon>

                  <v-list-item-title> Sair </v-list-item-title>
                </v-list-item>
              </v-list>
            </div>
          </div>
        </v-card>
      </div>
    </v-navigation-drawer>

    <v-main class="pb-16 pb-md-0">
      <v-app-bar color="transparent" flat>
        <v-btn
          v-if="$vuetify.breakpoint.mdOnly"
          class="ml-1"
          icon
          @click="drawer = !drawer"
        >
          <v-icon>mdi-menu</v-icon>
        </v-btn>

        <v-img
          v-if="$vuetify.breakpoint.smAndDown"
          :src="require('@/assets/logo.svg')"
          contain
          max-width="4rem"
        />

        <v-spacer />

        <v-btn
          class="rounded-md text-none"
          color="secondary"
          depressed
          small
          @click="switchToCreator()"
        >
          <v-icon class="mr-1" small>mdi-sync</v-icon>

          Criadores
        </v-btn>

        <div class="d-flex align-center">
          <v-divider class="mx-4" vertical />

          <v-avatar class="mr-2" color="background3" size="2.5rem">
            <v-img v-if="user?.image" :src="user.image" />

            <v-icon v-else color="surface lighten-1">
              mdi-account-outline
            </v-icon>
          </v-avatar>

          <span v-if="$vuetify.breakpoint.smAndUp" class="text-subtitle-1">
            Olá, {{ user?.name }}
          </span>
        </div>
      </v-app-bar>

      <v-banner
        v-if="user && !user.subscriber"
        class="mx-4 my-2 rounded-lg white"
        outlined
        two-line
      >
        <v-avatar slot="icon" color="secondary" size="3rem">
          <v-icon color="white"> mdi-information-variant </v-icon>
        </v-avatar>

        <div class="text-subtitle-1">
          💡 Lembrete: A sua assinatura venceu. Você está utilizando os recursos
          da conta ALIO Pro. Verifique a sua assinatura para continuar
          desfrutando de recursos Pro e alavancando seus resultados!
        </div>

        <template v-slot:actions>
          <div class="ma-4">
            <v-btn
              class="text-none rounded-lg"
              color="primary"
              to="/plans"
              depressed
            >
              Verificar assinatura
            </v-btn>
          </div>
        </template>
      </v-banner>

      <router-view />

      <v-btn
        href="https://api.whatsapp.com/send?phone=558296023487"
        target="_blank"
        color="green"
        :class="{
          'desktop-fab': $vuetify.breakpoint.mdAndUp,
          'mobile-fab': $vuetify.breakpoint.smAndDown,
        }"
        :small="$vuetify.breakpoint.smAndDown"
        absolute
        bottom
        fixed
        right
        dark
        fab
      >
        <v-icon :large="$vuetify.breakpoint.mdAndUp">mdi-whatsapp</v-icon>
      </v-btn>
    </v-main>

    <v-app-bar
      v-if="$vuetify.breakpoint.smAndDown"
      class="mx-3 mb-2 rounded-lg"
      color="background"
      height="auto"
      bottom
      app
    >
      <div class="d-flex flex-wrap fill-width justify-center">
        <router-link
          v-for="(item, i) in routes.slice(0, moreRoutes ? routes.length : 3)"
          v-slot="{ href, navigate, isActive }"
          class="ma-1"
          :to="item.path"
          :key="i"
          custom
        >
          <v-sheet
            v-ripple
            class="rounded-lg pointer"
            min-width="3.5rem"
            height="3rem"
            :class="{
              background2: isActive,
              disabled: item.disabled || item.isComing,
            }"
            :href="href"
            flat
            @click="navigate"
          >
            <div
              class="d-flex flex-column fill-height align-center justify-center"
            >
              <v-img
                :class="{
                  'disabled-icon': item.disabled || item.isComing,
                }"
                :src="require(`@/assets/menu/${item.icon}.svg`)"
                max-width="1.125rem"
                contain
              />

              <span
                class="text-mobile text-center"
                :class="
                  item.disabled || item.isComing
                    ? 'secondaryText--text'
                    : 'primaryText--text'
                "
              >
                {{ item.label }}
              </span>
            </div>
          </v-sheet>
        </router-link>

        <router-link
          v-if="moreRoutes"
          v-slot="{ href, navigate, isActive }"
          to="/profile"
          custom
        >
          <v-sheet
            v-ripple
            class="rounded-lg pointer ma-1"
            min-width="3.5rem"
            height="3rem"
            :color="isActive ? 'background' : 'transparent'"
            :href="href"
            flat
            @click="navigate"
          >
            <div
              class="d-flex flex-column fill-height align-center justify-center"
            >
              <v-img
                :src="require('@/assets/menu/settings-sliders.svg')"
                max-width="1.125rem"
                contain
              />

              <span class="textPrimary--text text-mobile text-center">
                Meus dados
              </span>
            </div>
          </v-sheet>
        </router-link>

        <v-sheet
          v-if="moreRoutes"
          v-ripple
          class="pointer ma-1"
          min-width="3.5rem"
          height="3rem"
          flat
          @click="signOut()"
        >
          <div
            class="d-flex flex-column fill-height align-center justify-center"
          >
            <v-img
              :src="require('@/assets/menu/exit.svg')"
              max-width="1.125rem"
              contain
            />

            <span class="textPrimary--text text-mobile text-center">
              Sair
            </span>
          </div>
        </v-sheet>

        <v-sheet
          v-ripple
          class="rounded-lg pointer ma-1"
          min-width="3.5rem"
          height="3rem"
          flat
          @click="moreRoutes = !moreRoutes"
        >
          <div
            class="d-flex flex-column fill-height align-center justify-center"
          >
            <v-icon color="primary" class="mb-1">
              {{ moreRoutes ? "mdi-dots-vertical" : "mdi-dots-horizontal" }}
            </v-icon>

            <span class="textPrimary--text text-mobile text-center">
              {{ moreRoutes ? "Menos" : "Mais" }}
            </span>
          </div>
        </v-sheet>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { request } from "@/services";

export default {
  name: "Main",

  data() {
    return {
      drawer: true,
      moreRoutes: false,
      routes: [
        {
          icon: "house-chimney",
          label: "Home",
          path: "/home",
        },
        {
          icon: "apps",
          label: "Campanhas",
          path: "/campaigns",
        },
        {
          icon: "wallet",
          label: "Financeiro",
          path: "/financial",
        },
        {
          icon: "following",
          label: "Creators",
          path: "/creators",
        },
        /* {
          icon: "indication",
          label: "Indicações",
          path: "/indications",
        },
        {
          icon: "template",
          label: "Planos",
          path: "/plans",
        }, */
      ],
    };
  },

  computed: {
    ...mapState(["user"]),
  },

  methods: {
    async switchToCreator() {
      try {
        const payload = {
          method: "changeToCreator",
        };

        const { data } = await request(payload);

        const url = `${process.env.VUE_APP_CLIENT_CREATOR_URL}/auth?tk=${data.authToken}`;
        window.location.href = url;
      } catch (error) {
        console.log(error);
      }
    },

    signOut() {
      this.$root.$emit("sign-out");
    },
  },
};
</script>

<style src="./style.scss" lang="scss" />
