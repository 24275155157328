<template>
  <v-main class="backgroundBlue">
    <v-container fill-height>
      <v-row justify="center">
        <v-col
          v-if="$route.name !== 'Start' && $vuetify.breakpoint.mdAndUp"
          cols="12"
          md="6"
          xl="4"
        >
          <div class="d-flex justify-center" style="height: 100vh">
            <v-img
              class="rounded-lg"
              :src="require(`@/assets/auth/img-auth.png`)"
              contain
            />
          </div>
        </v-col>

        <v-col
          cols="12"
          :md="$route.name === 'Start' ? '12' : '6'"
          xl="4"
          align-self="center"
        >
          <div
            v-if="$route.name !== 'Start' && $vuetify.breakpoint.smAndDown"
            class="mb-8"
          >
            <v-row class="d-flex justify-center">
              <v-col cols="6" sm="5">
                <v-img :src="require(`@/assets/logo-white.svg`)" />
              </v-col>
            </v-row>
          </div>

          <router-view />
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "Auth",
};
</script>

<style lang="scss" src="./style.scss" scoped />
