<template>
  <v-app v-if="!loading">
    <router-view />

    <AlertBar ref="alertBar" />
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import { request } from "./services";
import AlertBar from "./components/alertBar/AlertBar";

export default {
  name: "App",

  components: {
    AlertBar,
  },

  data() {
    return {
      loading: true,
    };
  },

  created() {
    this.handleSession();
  },

  beforeMount() {
    this.$root.$on("get-user", this.getUser);
    this.$root.$on("sign-out", this.signOut);
    this.$root.$on("alert", this.handleAlert);
  },

  beforeDestroy() {
    this.$root.$off("get-user", this.getUser);
    this.$root.$off("sign-out", this.signOut);
    this.$root.$off("alert", this.handleAlert);
  },

  methods: {
    ...mapActions(["setToken", "setUser", "setSignOut"]),

    async handleSession() {
      const token = this.$store.getters.token;

      if (token) {
        try {
          const payload = {
            method: "checkUserToken",
          };

          const { data } = await request(payload);

          if (data.message === "Token OK") this.getUser();
          else throw new Error();
        } catch {
          this.signOut();
        }
      }

      this.loading = false;
    },

    async getUser(auth = false) {
      const payload = {
        method: "getMarcaData",
      };

      const { data } = await request(payload);
      const user = data.marcaData;

      if (user.aceitouTermos !== "1")
        return this.$router
          .push({ path: `/auth/sign-up?step=terms` })
          .catch(() => {
            /* ignore */
          });

      const apiURL = process.env.VUE_APP_API_URL + "/";

      this.setUser({
        id: user.id,
        name: user.nome,
        username: user.username,
        biography: user.biografia,
        image: user.userImage ? apiURL + user.userImage : "",
        document: user.cpfCnpj,
        email: user.email,
        phone: user.telefone,
        acceptTerms: user.aceitouTermos === "1",
        subscriber: user.assinatura && user.assinatura.valida,
        ...(user.documentos &&
          Object.keys(user.documentos).length && {
            documents: {
              cnpjDoc: apiURL + user.documentos.cartaoCNPJ,
              responsibleDoc: apiURL + user.documentos.docResponsavel,
              selfie: apiURL + user.documentos.docSelfie,
              cnpj: user.documentos.numCNPJ,
              site: user.documentos.siteMarca,
              status: user.documentos.status,
            },
          }),
        ...(user.bankAccount && {
          bankAccount: {
            id: user.bankAccount.id,
            bank: user.bankAccount.banco,
            agency: user.bankAccount.agencia,
            account: user.bankAccount.conta,
            digit: user.bankAccount.digito,
            date: user.bankAccount.dataEnvio,
            pixKey: {
              type: user.bankAccount.tipoChave,
              key: user.bankAccount.chavePix,
            },
          },
        }),
        rates: {
          pix: Number(user.taxaPix),
          card: Number(user.taxaCartao),
        },
      });

      if (auth) this.$router.push({ path: "/home" });
    },

    handleAlert(event) {
      this.$refs.alertBar.handleAlert(event);
    },

    async signOut() {
      this.loading = true;

      await this.setSignOut();
      await this.$router.push({ path: "/auth" }).catch(() => {
        /* ignore */
      });

      this.loading = false;
    },
  },
};
</script>
