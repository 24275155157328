import Vue from "vue";
import VueRouter from "vue-router";
import beforeEach from "./beforeEach";
import Main from "../pages/main/Main.vue";
import Auth from "../pages/auth/Auth.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Main",
    redirect: "/home",
    meta: { requiresAuth: true },
    component: Main,
    children: [
      {
        path: "/home",
        name: "Home",
        meta: { title: "Início", requiresAuth: true },
        component: () => import("../pages/main/views/home/Home.vue"),
      },
      {
        path: "/new-campaign",
        redirect: "/new-campaign/details",
        component: () =>
          import("../pages/main/views/newCampaign/NewCampaign.vue"),
        children: [
          {
            path: "details",
            name: "Details",
            meta: { title: "Campanha", requiresAuth: true },
            component: () =>
              import(
                "../pages/main/views/newCampaign/children/details/Details.vue"
              ),
          },
          {
            path: "product/:id",
            name: "Product",
            meta: { title: "Produto", requiresAuth: true },
            component: () =>
              import(
                "../pages/main/views/newCampaign/children/product/Product.vue"
              ),
          },
          {
            path: "briefing/:id",
            name: "Briefing",
            meta: { title: "Briefing", requiresAuth: true },
            component: () =>
              import(
                "../pages/main/views/newCampaign/children/briefing/Briefing.vue"
              ),
          },
        ],
      },
      {
        path: "/campaigns",
        name: "Campaigns",
        meta: { title: "Campanhas", requiresAuth: true },
        component: () => import("../pages/main/views/campaigns/Campaigns.vue"),
      },
      {
        path: "/campaign/:id",
        component: () => import("../pages/main/views/campaign/Campaign.vue"),
        children: [
          {
            path: "applications",
            name: "Applications",
            meta: { title: "Aplicações", requiresAuth: true },
            component: () =>
              import(
                "../pages/main/views/campaign/children/applications/Applications.vue"
              ),
          },
          {
            path: "campaign",
            name: "Campaign",
            meta: { title: "Campanha", requiresAuth: true },
            component: () =>
              import(
                "../pages/main/views/campaign/children/campaign/Campaign.vue"
              ),
          },
          {
            path: "product",
            name: "Product",
            meta: { title: "Produto", requiresAuth: true },
            component: () =>
              import(
                "../pages/main/views/campaign/children/product/Product.vue"
              ),
          },
          {
            path: "briefing",
            name: "Briefing",
            meta: { title: "Briefing", requiresAuth: true },
            component: () =>
              import(
                "../pages/main/views/campaign/children/briefing/Briefing.vue"
              ),
          },
          {
            path: "chat/:chatID?",
            name: "Chat",
            meta: { title: "Chat", requiresAuth: true },
            component: () =>
              import("../pages/main/views/campaign/children/chat/Chat.vue"),
          },
        ],
      },
      {
        path: "/financial",
        name: "Financial",
        redirect: "/financial/payments",
        component: () => import("../pages/main/views/financial/Financial.vue"),
        children: [
          {
            path: "payments",
            name: "Payments",
            meta: { title: "Pagamentos", requiresAuth: true },
            component: () =>
              import(
                "../pages/main/views/financial/children/payments/Payments.vue"
              ),
          },
          {
            path: "withdraw",
            name: "Withdraw",
            meta: { title: "Saque", requiresAuth: true },
            component: () =>
              import(
                "../pages/main/views/financial/children/withdraw/Withdraw.vue"
              ),
          },
        ],
      },
      {
        path: "/creators",
        name: "Creators",
        meta: { title: "Criadores", requiresAuth: true },
        component: () => import("../pages/main/views/creators/Creators.vue"),
      },
      /*  {
        path: "/plans",
        name: "Plans",
        meta: { title: "Planos", requiresAuth: true },
        component: () => import("../pages/main/views/plans/Plans.vue"),
      }, */
      {
        path: "/profile",
        name: "Profile",
        meta: { title: "Meus Dados", requiresAuth: true },
        component: () => import("../pages/main/views/profile/Profile.vue"),
      },
      /* {
        path: "/indications",
        name: "Indications",
        meta: { title: "Indicações", requiresAuth: true },
        component: () =>
          import("../pages/main/views/indications/Indications.vue"),
      }, */
    ],
  },
  {
    path: "/auth",
    component: Auth,
    children: [
      {
        path: "",
        name: "SingIn",
        meta: { title: "Efetuar Acesso", preventAuth: true },
        component: () => import("../pages/auth/views/signIn/SignIn.vue"),
      },
      {
        path: "sign-up",
        name: "SingUp",
        meta: { title: "Criar Conta", preventAuth: true },
        component: () => import("../pages/auth/views/signUp/SignUp.vue"),
      },
      {
        path: "forgot-password",
        name: "Forgot Password",
        meta: { title: "Esqueceu sus senha", preventAuth: true },
        component: () =>
          import("../pages/auth/views/forgotPassword/ForgotPassword.vue"),
      },
      {
        path: "start",
        name: "Start",
        meta: { title: "Iniciando", preventAuth: true },
        component: () => import("../pages/auth/views/start/Start.vue"),
      },
    ],
  },
  {
    path: "*",
    redirect: "/home",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(beforeEach);

export default router;
